<template>
    <div style="width: 400px">
        <v-text-field
            solo
            flat
            hide-details
            dark
            label="Search"
            color="white"
            background-color="primary lighten-1"
            class="blue--text hidden-sm-and-down"
            prepend-inner-icon="search"
        ></v-text-field>
    </div>
</template>
<script>
export default {
    name: "",
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>