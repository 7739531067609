var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      attrs: {
        app: "",
        "clipped-left": "",
        color: "primary",
        "elevate-on-scroll": ""
      }
    },
    [
      _c("v-app-bar-nav-icon", {
        attrs: { dark: "" },
        on: {
          click: function($event) {
            return _vm.$emit("toggleNav")
          }
        }
      }),
      _c("app-logo-wrapper"),
      _vm.can("search") ? _c("app-search") : _vm._e(),
      _c("v-spacer"),
      _vm.can("applications.create")
        ? _c(
            "v-btn",
            {
              attrs: {
                dark: "",
                color: "primary darken-1",
                href: _vm.$utils.buildUrl("portal", "applications/create")
              }
            },
            [
              _c("v-icon", { staticClass: "mr-1" }, [
                _vm._v("mdi-clipboard-plus")
              ]),
              _c("span", { staticClass: "hidden-sm-and-down" }, [
                _vm._v("New Application")
              ])
            ],
            1
          )
        : _vm._e(),
      _c("app-user")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }