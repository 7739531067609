var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "400px" } },
    [
      _c("v-text-field", {
        staticClass: "blue--text hidden-sm-and-down",
        attrs: {
          solo: "",
          flat: "",
          "hide-details": "",
          dark: "",
          label: "Search",
          color: "white",
          "background-color": "primary lighten-1",
          "prepend-inner-icon": "search"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }