<template>
    <v-app-bar app clipped-left color="primary" elevate-on-scroll>
        <v-app-bar-nav-icon
            @click="$emit('toggleNav')"
            dark
        ></v-app-bar-nav-icon>
        <app-logo-wrapper></app-logo-wrapper>
        <app-search v-if="can('search')"></app-search>
        <v-spacer></v-spacer>
        <v-btn
            v-if="can('applications.create')"
            dark
            color="primary darken-1"
            :href="$utils.buildUrl('portal', 'applications/create')"
        >
            <v-icon class="mr-1">mdi-clipboard-plus</v-icon>
            <span class="hidden-sm-and-down">New Application</span>
        </v-btn>
        <app-user></app-user>
    </v-app-bar>
</template>
<script>
import AppSearch from "@components/search/components/AppSearch.vue";
import AppLogoWrapper from "./components/AppLogoWrapper";
import AppUser from "@components/users/components/AppUser.vue";

export default {
    name: "app-header",
    components: {
        AppSearch,
        AppLogoWrapper,
        AppUser,
    },
    props: {},
    data() {
        return {
            fab: false,
        };
    },
    methods: {},
    mounted() {
        this.$emit("mounted");
    },
};
</script>

<style scoped>
</style>